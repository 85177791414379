import './RoundedButton.css';

type RoundedButtonStyle =
  | 'action-light'
  | 'action-dark'
  | 'action-outline-red'
  | 'action-outline-black'
  | 'file-tag';

interface RoundedButtonProps {
  label: string;
  icon: string;
  onClick: () => void;
  face: RoundedButtonStyle;
}

function RoundedButton({ label, icon, face, onClick }: RoundedButtonProps) {
  let iconOnly = label.length === 0 && icon.length > 0;
  let labelOnly = icon.length === 0 && label.length > 0;

  if (labelOnly || iconOnly)
    return (
      <div className={`rounded-button  ${face}`} onClick={onClick}>
        {!labelOnly && (
          <span
            className={`material-symbols-outlined rounded-button-icon  ${face}`}
          >
            {icon}
          </span>
        )}
        {!iconOnly && (
          <p className={`rounded-button-label  ${face}`}>{label}</p>
        )}
      </div>
    );
  else
    return (
      <div className={`rounded-button  ${face}`} onClick={onClick}>
        {
          <>
            <span className={`material-symbols-outlined ${face}`}>{icon}</span>
            {label}
          </>
        }
      </div>
    );
}

export default RoundedButton;
