import { InfoRequestModel } from "../models/info-request-model";
import { apiPut, useGet } from "./method";

// Get info request
export const useApiInfoRequest = (id: string) =>
  useGet<InfoRequestModel>("public/info-request/" + id);

// Modify info request by id
export const apiUpdateInfoRequestByGuid = async (
  id: string,
  data: InfoRequestModel
) => {
  return await apiPut<any>("public/info-request/" + id, data);
};
