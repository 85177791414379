import axios from "axios";
//console.log('ENV for debugging purposes...');
//console.log(process.env);

const apiBaseUrlEnv = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "http://localhost";
const backendBasePortEnv = process.env.NODE_ENV === "development" ? 8080 : null;

export const apiBaseURL = backendBasePortEnv
  ? `${apiBaseUrlEnv}:${backendBasePortEnv}`
  : apiBaseUrlEnv;

// Create instance
const instance = axios.create({
  baseURL: apiBaseURL,
  headers: {
    "Content-Type": "application/json",
    timeout: 5000,
    "x-api-key": "fc02a10169e34719b247feff9265f1a3",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
