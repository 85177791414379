import { useState } from "react";
import "../../index.css";
import { Layout } from "../../components/Layout";
export const ErrorPage = () => {
  const [errorMessage, setErrorMessage] = useState("Lomaketta ei löytynyt.");
  return (
    <Layout>
      <div
        style={{
          backgroundColor: "red",
          color: "white",

          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {errorMessage}
      </div>
    </Layout>
  );
};
