import { ApartmentModel } from './apartment-model';
import { NoteModel } from './note-model';

export type HousingCompanyModel = {
  id: number;
  customer_name: string | null;
  business_id: string | null;
  system_status: string | null;
  cust_data_buildyear_org: string | null;
  cust_data_ownership_type: string | null;
  cust_data_property_type: string | null;
  cust_data_purpose_use: string | null;
  cust_data_num_buildings: number | null;
  cust_data_num_floors: string | null;
  cust_data_address: string | null;
  cust_data_zipcode: string | null;
  cust_data_city: string | null;
  cust_data_heating: string | null;
  cust_data_apartment_surfacearea: number | null;
  cust_data_business_surfacearea: number | null;
  cust_data_carspace_owned_by_hc: number | null;
  cust_data_aprt_owned: number | null;
  cust_data_business_owned: number | null;
  cust_data_organization: string | null;
  cust_data_manager_name: string | null;
  cust_data_manager_phone: string | null;
  cust_data_manager_email: string | null;
  accountant_name: string | null;
  accountant_phone: string | null;
  accountant_email: string | null;
  number_of_loans: number | null;
  pending_to_be_added_for_lead_id: number | null;
  contract_signed_date: string | null;
  mml_mandate_status: string | null;
  contract_status: string | null;
  last_import: string | null;
  notes: Array<NoteModel>;
  energy_certificate: string | null;
  apartments: Array<ApartmentModel> | undefined;
  createdAt: string | null;
} & {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | undefined
    | HousingCompanyModel[]
    | ApartmentModel[]
    | NoteModel[]
    | boolean;
};

export const propertyType = {
  ZERO: '',
  ONE: 'Asuinkerrostalo',
  TWO: 'Rivitalo',
  THREE: 'Paritalo',
  FOUR: 'Pientalo',
};
export const propertyTypes = Object.values(propertyType);
export const contractStatus = {
  ZERO: '',
  ONE: 'Puuttuu',
  TWO: 'Kesken',
  THREE: 'Allekirjoitettu',
};
export const contractStatuses = Object.values(contractStatus);
export const mandateStatus = {
  EMPTY: '',
  ZERO: 'Puuttuu',
  ONE: 'Ei HTJ:ssä',
  TWO: 'Kesken',
  THREE: 'Myönnetty',
};
export const mandateStatuses = Object.values(mandateStatus);

export const ownershipType = {
  ZERO: '',
  ONE: 'Oma',
  TWO: 'Vuokra',
  THREE: 'Muu',
};
export const ownershipTypes = Object.values(ownershipType);

export const energyClass = {
  ZERO: '',
  ONE: 'A',
  TWO: 'B',
  THREE: 'C',
  FOUR: 'D',
  FIVE: 'E',
  SIX: 'F',
  SEVEN: 'G',
  EIGHT: 'Ei energiatodistusta',
};
export const energyClasses = Object.values(energyClass);

export const emptyHousingcompany: HousingCompanyModel = {
  id: -1,
  customer_name: null,
  business_id: null,
  cust_data_buildyear_org: null,
  cust_data_ownership_type: null,
  cust_data_property_type: null,
  cust_data_purpose_use: null,
  system_status: null,
  cust_data_num_buildings: null,
  cust_data_num_floors: null,
  cust_data_address: null,
  cust_data_zipcode: null,
  cust_data_city: null,
  cust_data_heating: null,
  cust_data_apartment_surfacearea: null,
  cust_data_business_surfacearea: null,
  cust_data_carspace_owned_by_hc: null,
  cust_data_organization: null,
  cust_data_aprt_owned: null,
  cust_data_business_owned: null,
  cust_data_manager_name: null,
  cust_data_manager_phone: null,
  cust_data_manager_email: null,
  accountant_name: null,
  pending_to_be_added_for_lead_id: null,
  accountant_phone: null,
  accountant_email: null,
  number_of_loans: null,
  contract_signed_date: null,
  mml_mandate_status: null,
  contract_status: null,
  last_import: null,
  energy_certificate: null,
  apartments: undefined,
  notes: [],
  createdAt: null,
};
