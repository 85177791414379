import { NoteModel } from './note-model';

export type CaseModel = {
  id: number;
  loan_data_name: string | null;
  loan_data_credit_number: string | null;
  loan_data_credit_id: string | null;
  loan_data_margin: number | null;
  loan_data_agreement: number | null;
  loan_data_lifting: number | null;
  loan_data_bank: string | null;
  loan_data_loan_type: string | null;
  loan_data_legend: null | string;
  loan_data_interest_rate_bond: string | null;
  loan_data_interestrate_changed_date: string | null;
  loan_data_interestrate_percent: number | null;
  loan_data_period: number | null;
  loan_data_end_date: string | null;
  loan_data_agreed_shortening_period: number | null;
  loan_data_status: string | null;
  loan_data_debtor_benefits: number | null;
  loan_data_old_fashioned_loan: null | string;
  moderator_data_id: number | null;
  loan_data_first_interest_payment_date: null | string;
  loan_data_first_purchase_date: null | string;
  loan_data_adjustment_reduction_margin: number | null;
  loan_data_first_reduction_date: null | string;
  loan_data_transfer_tax: null | string;
  loan_data_total_capital_external_customers: number | null;
  loan_data_bank_group: null | string;
  loan_data_customerid: string | null;
  loan_data_customername: null | string;
  loan_data_priority: null | string;
  loan_data_disclaimer_one: null | string;
  loan_data_disclaimer_two: null | string;
  system_status: string | null;
  system_status_deadline: string | null;
  loan_data_purpose_of_the_loan: string | null;
  customer_name: string | null;
  notes: Array<NoteModel>;
  customer_data_id: number | null;
  loan_type: string;
  lead_names: Array<string> | null;
  moderator_name: string | null;
  contract_status: string | null;
  mml_mandate_status: string | null;
  contract_signed_date: string | null;
  createdAt: string | null;
} & {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | undefined
    | string[]
    | NoteModel[]
    | boolean;
};

export const caseStatus = {
  NEW: 'new',
  CONTACTED: 'contacted',
  FINISHED: 'finished',
  ARCHIVED: 'archived',
};
export const caseStatuses = Object.values(caseStatus);

export const loanInterest = {
  ZERO: '',
  ONE: '1kk Euribor',
  TWO: '3kk Euribor',
  SIX: '6kk Euribor',
  TWELVE: '12kk Euribor',
  PRIME: 'Prime',
  OTHER: 'Muu',
};
export const loanInterests = Object.values(loanInterest);

export const newLoanInterest = {
  ZERO: '',
  TWO: '3kk Euribor',
  SIX: '6kk Euribor',
  TWELVE: '12kk Euribor',
};
export const newLoanInterests = Object.values(newLoanInterest);

export const caseBank = {
  ZERO: '',
  AKTIA: 'Aktia',
  DANSKEBANK: 'Danske Bank',
  HANDELSBANKEN: 'Handelsbanken',
  HYPO: 'HYPO',
  NORDEA: 'Nordea',
  OP: 'OP',
  OMASAASTOPANKKI: 'Oma Säästöpankki',
  POPPANKKI: 'POP Pankki',
  SPANKKI: 'S-Pankki',
  SAASTOPANKKI: 'Säästöpankki',
  ALANDSBANKEN: 'Ålandsbanken',
  OTHER: 'Muu',
};
export const caseBanks = Object.values(caseBank);

export const loanType = {
  NEW: 'new',
  EXISTING: 'existing',
};
export const loanTypes = Object.values(loanType);

export const loanPaymentType = {
  ZERO: '',
  ONE: 'tasalyhennys',
  TWO: 'annuiteetti',
  THREE: 'tasaerä',
};
export const loanPaymentTypes = Object.values(loanPaymentType);

export const loanShorteningPeriod = {
  ZERO: '',
  ONE: '1',
  TWO: '3',
  THREE: '4',
  FOUR: '6',
  FIVE: '12',
};
export const loanShorteningPeriods = Object.values(loanShorteningPeriod);

export const newLoanShorteningPeriod = {
  ZERO: '',
  ONE: '1',
  THREE: '4',
  FOUR: '6',
  FIVE: '12',
};
export const newLoanShorteningPeriods = Object.values(newLoanShorteningPeriod);
