import { Route, Routes } from "react-router-dom";
import { ErrorPage } from "./pages/ErrorPage";
import { InfoRequestPage } from "./pages/InfoRequestPage";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path={"/public/info-request/:infoRequestId"}
        element={<InfoRequestPage />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
