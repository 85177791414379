import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: "var(--secondary-color)",
        marginLeft: "auto",
        marginRight: "auto",
        boxSizing: "border-box",
        padding: 22,
        borderRadius: 5,
        width: 800,
        marginTop: 22,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1);",
      }}
    >
      {children}
    </div>
  );
};
