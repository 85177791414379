import { useCallback, useEffect, useState } from "react";
import { AxiosResponse, AxiosError } from "axios";
import instance from "./instance";
import { ApiResponse, ApiResponseWithRefresh } from "./response";

export function useGet<T>(url: string): ApiResponseWithRefresh<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  const request = useCallback(async () => {
    try {
      let response: AxiosResponse<T> = await instance.get(url);
      setData(response.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    request();
  }, [request, url]); // Initial request on page load

  const refresh = () => {
    request(); // Request data again when this function is called
  };

  return { data, loading, error, refresh };
}

export async function apiPut<T>(
  url: string,
  data: any
): Promise<ApiResponse<T>> {
  try {
    const response: AxiosResponse<T> = await instance.put(url, data);
    return Promise.resolve({
      data: response.data,
      error: null,
      loading: false,
    });
  } catch (err: any) {
    return Promise.reject({ data: null, error: err, loading: false });
  }
}
