import React, { useState } from "react";
import "./TextInput.css";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-date-range";
import { fi } from "date-fns/locale";
import { format } from "date-fns";
import RoundedButton from "./RoundedButton";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface TextInputProps {
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  required: boolean;
  enabled: boolean;
  initialValue: string;
  validationPattern: RegExp;
  inputType: string;
}
TextInput.defaultProps = {
  inputType: "text",
  onBlur: () => {},
};
function TextInput(props: TextInputProps) {
  const addDecimals = (
    originalStringNumber: string,
    decimalPlaces: number
  ): string => {
    if (originalStringNumber === "") return "";
    var numberWithDecimals = "";

    // Add all numbers before comma
    for (let index = 0; index < originalStringNumber.length; index++) {
      if (originalStringNumber[index] === ",") break;
      else numberWithDecimals += originalStringNumber[index];
    }
    // Add the comma
    numberWithDecimals += ",";

    // Add decimals
    if (originalStringNumber.indexOf(",") > 0) {
      if (originalStringNumber.charAt(originalStringNumber.indexOf(",") + 1))
        numberWithDecimals += originalStringNumber.charAt(
          originalStringNumber.indexOf(",") + 1
        );
      else numberWithDecimals += "0";

      if (originalStringNumber.charAt(originalStringNumber.indexOf(",") + 2))
        numberWithDecimals += originalStringNumber.charAt(
          originalStringNumber.indexOf(",") + 2
        );
      else numberWithDecimals += "0";

      if (decimalPlaces === 3) {
        if (originalStringNumber.charAt(originalStringNumber.indexOf(",") + 3))
          numberWithDecimals += originalStringNumber.charAt(
            originalStringNumber.indexOf(",") + 3
          );
        else numberWithDecimals += "0";
      }
    } else
      numberWithDecimals +=
        props.inputType === "number3decimals" ? "000" : "00";

    return numberWithDecimals;
  };

  const fieldName = props.label + "-input";
  const [inputValue, setInputValue] = useState(
    props.inputType === "number" || props.inputType === "number3decimals"
      ? formatNumber(props.initialValue)
      : props.initialValue
  );
  const [isValid, setIsValid] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value;
    if (props.inputType === "numberNoDigits") {
      newValue = formatNumber(newValue);
      newValue = newValue.toString().replace(/\D+/g, "");
    }
    if (props.inputType === "number") {
      newValue = formatNumber(newValue);
    }
    if (props.inputType === "number3decimals") {
      newValue = formatNumber3Decimals(newValue);
    }
    setInputValue(newValue);
    setIsValid(props.validationPattern.test(newValue));
    props.onChange(newValue);
  };
  const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value;
    if (props.inputType === "numberNoDigits") {
      newValue = formatNumber(newValue);
      newValue = newValue.toString().replace(/\D+/g, "");
    }
    if (props.inputType === "number") {
      newValue = formatNumber(newValue);
      newValue = addDecimals(newValue, 2);
    }
    if (props.inputType === "number3decimals") {
      newValue = formatNumber3Decimals(newValue);
      newValue = addDecimals(newValue, 3);
    }
    setInputValue(newValue);
    setIsValid(props.validationPattern.test(newValue));
    props.onBlur(newValue);
  };

  return (
    <div
      style={{ position: "relative" }}
      className={
        (isValid ? "text-input-container" : "text-input-container invalid") +
        (props.enabled ? "" : " disabled")
      }
    >
      <label htmlFor={fieldName}>
        {props.label}
        {props.required && <span style={{ color: "red" }}> *</span>}
      </label>
      <input
        disabled={!props.enabled}
        onChange={(event) => handleInputChange(event)}
        onBlur={(event) => handleInputBlur(event)}
        type={"text"}
        id={fieldName}
        onClick={() => {
          if (props.inputType === "date") setShowDatePicker(!showDatePicker);
        }}
        name={fieldName}
        value={inputValue}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
      ></input>
      {!isValid && <small>{t("checkThisField")}</small>}
      {props.inputType === "date" && showDatePicker && (
        <div
          style={{
            position: "absolute",
            left: "-100px",
            display: "flex",
            top: "57px",
            zIndex: 340,
          }}
        >
          <div
            onClick={() => setShowDatePicker(false)}
            style={{
              backgroundColor: "black",
              opacity: 0.5,
              transform: "scale(100)",
              width: 100,
              height: 100,
              zIndex: -100,
            }}
          ></div>
          <div
            style={{
              overflow: "hidden",
              margin: 0,
              borderRadius: 16,
            }}
          >
            <Calendar
              onChange={(item) => {
                setShowDatePicker(false);
                const newValue = format(item, "dd.MM.yyyy");
                setInputValue(newValue);
                setIsValid(props.validationPattern.test(newValue));
                props.onChange(newValue);
                props.onBlur(newValue);
              }}
              locale={fi}
              showMonthArrow={false}
              color="#4b002b"
            />
          </div>

          <div style={{ position: "absolute", bottom: "22px", right: "22px" }}>
            <RoundedButton
              label={"Tyhjennä"}
              icon={""}
              onClick={function (): void {
                setShowDatePicker(false);
                const newValue = "";
                setInputValue(newValue);
                setIsValid(props.validationPattern.test(newValue));
                props.onChange(newValue);
                props.onBlur(newValue);
              }}
              face={"action-outline-red"}
            ></RoundedButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default TextInput;

function formatNumber(newValue: string): string {
  if (newValue === "") return "";
  var withDecimals = newValue
    .replace(/[^0-9.,]/g, "") // Remove non-number characters except dots and commas
    .replace(/,/g, "."); // Replace commas with dots
  const charsAfterDot = withDecimals.split(".");
  if (charsAfterDot.length > 1 && charsAfterDot[1].length > 2) {
    withDecimals =
      charsAfterDot[0] + "." + charsAfterDot[1][0] + charsAfterDot[1][1];
  }
  const pendingEndDot = withDecimals.charAt(withDecimals.length - 1) === ".";
  const pendingEndZero = withDecimals.endsWith(".0");
  const pendingEndZeros = withDecimals.endsWith(".00");
  withDecimals = parseFloat(withDecimals)
    .toLocaleString("fi-FI", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    .replaceAll("epäluku", "");

  if (pendingEndZeros) {
    withDecimals += ",00";
  } else if (pendingEndZero) {
    withDecimals += ",0";
  } else if (pendingEndDot) withDecimals += ",";
  return withDecimals;
}

function formatNumber3Decimals(newValue: string): string {
  if (newValue === "") return "";

  var withDecimals = newValue
    .replace(/[^0-9.,]/g, "") // Remove non-number characters except dots and commas
    .replace(/,/g, "."); // Replace commas with dots
  const charsAfterDot = withDecimals.split(".");
  if (charsAfterDot.length > 1 && charsAfterDot[1].length > 3) {
    withDecimals =
      charsAfterDot[0] +
      "." +
      charsAfterDot[1][0] +
      charsAfterDot[1][1] +
      charsAfterDot[1][2];
  }
  const pendingEndDot = withDecimals.charAt(withDecimals.length - 1) === ".";
  const pendingEndZero = withDecimals.endsWith(".0");
  const pendingEndZeros = withDecimals.endsWith(".00");
  withDecimals = parseFloat(withDecimals)
    .toLocaleString("fi-FI", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    })
    .replaceAll("epäluku", "");

  if (pendingEndZeros) {
    withDecimals += ",00";
  } else if (pendingEndZero) {
    withDecimals += ",0";
  } else if (pendingEndDot) withDecimals += ",";
  return withDecimals;
}

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
    // Do something when Enter key is pressed
    console.log("Enter key pressed");

    // Blur the input to remove focus
    event.currentTarget.blur();
  }
};
