import { useNavigate, useParams } from "react-router-dom";
import { apiUpdateInfoRequestByGuid, useApiInfoRequest } from "../../api/api";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import RoundedButton from "../../components/RoundedButton";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  energyClasses,
  ownershipTypes,
  propertyTypes,
} from "../../models/housing-company-model";
import {
  caseBanks,
  loanInterests,
  loanPaymentTypes,
  loanShorteningPeriods,
} from "../../models/case-model";

interface MyComponentState {
  myMap: Map<string, any>;
}
export const InfoRequestPage = () => {
  const { infoRequestId } = useParams();
  const infoRequest = useApiInfoRequest(infoRequestId!);
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [moreInfo, setMoreInfo] = useState("");
  const { t } = useTranslation();

  const selectInputs: string[] = [
    "cust_data_property_type",
    "cust_data_ownership_type",
    "energy_certificate",
    "loan_data_loan_type",
    "loan_data_agreed_shortening_period",
    "loan_data_interest_rate_bond",
    "loan_data_bank",
  ];
  const numberNoDigitsInputs: string[] = [
    "cust_data_buildyear_org",
    "cust_data_apartment_surfacearea",
    "cust_data_business_surfacearea",
    "cust_data_num_buildings",
    "cust_data_buildyear",
  ];
  const numberInputs: string[] = [
    "loan_data_agreement",
    "loan_data_total_capital_external_customers",
    "loan_data_period",
  ];
  const number3digitInputs: string[] = [
    "loan_data_margin",
    "loan_data_interestrate_percent",
  ];
  const dateInputs: string[] = ["loan_data_interestrate_changed_date"];

  const [fieldMap, setfieldMap] = useState<MyComponentState>({
    myMap: new Map<string, any>(),
  });
  // Function to update the map by adding or overwriting a key-value pair
  const addToMap = (key: string, value: any) => {
    const newMap = new Map(fieldMap.myMap);
    newMap.set(key, value);
    setfieldMap({ myMap: newMap });
  }; //pp.kk.vvvv -> vvvv-kk-pp
  function inputDateToPayloadDate(inputDate: string) {
    if (inputDate === undefined || inputDate === null || inputDate.length === 0)
      return "";
    // Split the input date by the dot (.) separator
    const dateParts = inputDate.split(".");

    // Ensure that we have exactly 3 parts (day, month, year)
    if (dateParts.length !== 3) {
      return "";
    }

    // Extract day, month, and year
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    // Create a new Date object using yyyy-mm-dd format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function getOptionsFor(fieldName: string): Array<string> {
    switch (fieldName) {
      case "cust_data_property_type":
        return [...propertyTypes];
      case "cust_data_ownership_type":
        return [...ownershipTypes];
      case "energy_certificate":
        return [...energyClasses];
      case "loan_data_loan_type":
        return [...loanPaymentTypes];
      case "loan_data_agreed_shortening_period":
        return [...loanShorteningPeriods];
      case "loan_data_interest_rate_bond":
        return [...loanInterests]; //TODO what about new loan type?
      case "loan_data_bank":
        return [...caseBanks];
      default:
        return [""];
    }
  }
  useEffect(() => {
    if (infoRequest.error != null) navigate("/error");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoRequest.error]);

  function buildInputField(it: string): ReactNode {
    if (numberNoDigitsInputs.includes(it))
      return (
        <TextInput
          label={t(it)}
          placeholder={""}
          onChange={function (value: string): void {
            addToMap(it, value);
          }}
          required={false}
          enabled={true}
          inputType="numberNoDigits"
          initialValue={""}
          validationPattern={/.*/}
        ></TextInput>
      );
    if (numberInputs.includes(it))
      return (
        <TextInput
          label={t(it)}
          placeholder={""}
          onChange={function (value: string): void {
            addToMap(it, value);
          }}
          required={false}
          enabled={true}
          inputType="number"
          initialValue={""}
          validationPattern={/.*/}
        ></TextInput>
      );
    if (number3digitInputs.includes(it))
      return (
        <TextInput
          label={t(it)}
          placeholder={""}
          onChange={function (value: string): void {
            addToMap(it, value);
          }}
          required={false}
          inputType="number3decimals"
          enabled={true}
          initialValue={""}
          validationPattern={/.*/}
        ></TextInput>
      );
    if (selectInputs.includes(it))
      return (
        <SelectInput
          label={t(it)}
          onChange={function (value: string): void {
            addToMap(it, value);
          }}
          required={false}
          initialValue={0}
          items={getOptionsFor(it)}
          enabled={true}
        ></SelectInput>
      );
    if (dateInputs.includes(it))
      return (
        <TextInput
          label={t(it)}
          placeholder={""}
          onChange={function (value: string): void {
            addToMap(it, value);
          }}
          inputType="date"
          required={false}
          enabled={true}
          initialValue={""}
          validationPattern={/.*/}
        ></TextInput>
      );
    return (
      <TextInput
        label={t(it)}
        placeholder={""}
        onChange={function (value: string): void {
          addToMap(it, value);
        }}
        required={false}
        enabled={true}
        initialValue={""}
        validationPattern={/.*/}
      ></TextInput>
    );
  }
  if (isSent) return buildSent();
  else
    return (
      <Layout>
        <>
          {infoRequest.data &&
            infoRequest.data?.requested_fields.map((it: string) =>
              buildInputField(it)
            )}
          <div style={{ clear: "both" }}></div>
          <label htmlFor="detailpurpose">Lisätiedot</label>
          <div style={{ height: 12 }}></div>
          <textarea
            style={{ marginBottom: 12 }}
            name="detailpurpose"
            id="detailpurpose"
            onChange={function (event: ChangeEvent<HTMLTextAreaElement>): void {
              setMoreInfo(event.target.value);
            }}
            defaultValue={""}
          ></textarea>
          <div style={{ clear: "both" }}></div>
          <RoundedButton
            label={"Lähetä tiedot"}
            icon={""}
            onClick={function (): void {
              if (
                fieldMap.myMap.get("cust_data_zipcode") &&
                !(
                  fieldMap.myMap.get("cust_data_zipcode").length === 0 ||
                  fieldMap.myMap.get("cust_data_zipcode").length === 5
                )
              ) {
                alert("Postinumeron pituus on virheellinen!");
                return;
              }
              apiUpdateInfoRequestByGuid(infoRequestId!, {
                id: -1,
                guid: "",
                system_status: null,
                requested_fields: [],
                more_info: moreInfo,
                url: null,
                createdAt: null,
                updatedAt: null,
                customer_name: nullIfEmpty(fieldMap.myMap.get("customer_name")),
                business_id: nullIfEmpty(fieldMap.myMap.get("business_id")),
                cust_data_address: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_address")
                ),
                cust_data_apartment_surfacearea: numberNullIfEmpty(
                  fieldMap.myMap.get("cust_data_apartment_surfacearea")
                ),
                cust_data_city: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_city")
                ),
                cust_data_zipcode: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_zipcode")
                ),
                cust_data_business_surfacearea: numberNullIfEmpty(
                  fieldMap.myMap.get("cust_data_business_surfacearea")
                ),
                cust_data_buildyear_org: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_buildyear_org")
                ),
                cust_data_property_type: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_property_type")
                ),
                cust_data_ownership_type: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_ownership_type")
                ),
                cust_data_num_buildings: numberNullIfEmpty(
                  fieldMap.myMap.get("cust_data_num_buildings")
                ),
                energy_certificate: nullIfEmpty(
                  fieldMap.myMap.get("energy_certificate")
                ),
                cust_data_organization: nullIfEmpty(
                  fieldMap.myMap.get("cust_data_organization")
                ),
                loan_data_name: nullIfEmpty(
                  fieldMap.myMap.get("loan_data_name")
                ),
                loan_data_period: numberNullIfEmpty(
                  fieldMap.myMap.get("loan_data_period")
                ),
                loan_data_agreement: numberNullIfEmpty(
                  fieldMap.myMap.get("loan_data_agreement")
                ),
                loan_data_total_capital_external_customers: numberNullIfEmpty(
                  fieldMap.myMap.get(
                    "loan_data_total_capital_external_customers"
                  )
                ),
                loan_data_loan_type: nullIfEmpty(
                  fieldMap.myMap.get("loan_data_loan_type")
                ),
                loan_data_agreed_shortening_period: numberNullIfEmpty(
                  fieldMap.myMap.get("loan_data_agreed_shortening_period")
                ),
                loan_data_interest_rate_bond: nullIfEmpty(
                  fieldMap.myMap.get("loan_data_interest_rate_bond")
                ),
                loan_data_interestrate_changed_date: nullIfEmpty(
                  inputDateToPayloadDate(
                    fieldMap.myMap.get("loan_data_interestrate_changed_date")
                  )
                ),
                loan_data_interestrate_percent: numberNullIfEmpty(
                  fieldMap.myMap.get("loan_data_interestrate_percent")
                ),
                loan_data_bank: nullIfEmpty(
                  fieldMap.myMap.get("loan_data_bank")
                ),
                loan_data_margin: numberNullIfEmpty(
                  fieldMap.myMap.get("loan_data_margin")
                ),
              })
                .then((response) => {
                  setIsSent(true);
                })
                .catch((response) => {
                  if (response.error) {
                    console.log("error:", response);
                    toast.error(response.error.request.response);
                  }
                });
            }}
            face={"action-dark"}
          ></RoundedButton>
        </>
      </Layout>
    );
};

function nullIfEmpty(input: string): string | null {
  if (input === "" || input === null || input === undefined) {
    return null;
  }
  return input;
}

function numberNullIfEmpty(input: string) {
  if (input === null || input === undefined) return null;
  const parsedValue = parseFloat(
    input.replaceAll(" ", "").replaceAll(",", ".")
  );
  return isNaN(parsedValue) ? null : parsedValue;
}

function buildSent() {
  return (
    <Layout>
      <h1>Kiitos!</h1>
      <p>Voit nyt sulkea tämän sivun.</p>
    </Layout>
  );
}
