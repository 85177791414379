import React, { useState } from 'react';
import './SelectInput.css';
import { useTranslation } from 'react-i18next';

interface SelectInputProps {
  label: string;
  items: string[];
  onChange: (value: string) => void;
  required: boolean;
  initialValue: number;
  enabled: boolean;
}
SelectInput.defaultProps = {
  enabled: true,
};
function SelectInput(props: SelectInputProps) {
  const fieldName = props.label + '-input';
  const { t } = useTranslation();
  var selectedValue = props.items[props.initialValue];
  const [invalid, setInvalid] = useState(false);
  function onValueChange(event: { target: { value: string } }) {
    selectedValue = event.target.value;
    setInvalid(selectedValue === '');
    props.onChange(selectedValue);
  }
  return (
    <div
      className={
        invalid ? 'select-input-container invalid' : 'select-input-container'
      }
    >
      <label
        htmlFor={fieldName}
        style={{ opacity: props.enabled ? '1' : '0.5' }}
      >
        {props.label}
        {props.required && <span style={{ color: 'red' }}> *</span>}
      </label>
      <select
        onChange={onValueChange}
        id={fieldName}
        name={fieldName}
        style={{ opacity: props.enabled ? '1' : '0.5' }}
        disabled={!props.enabled}
      >
        {props.items.map((item, index) => (
          <option key={index} value={item} selected={selectedValue === item}>
            {t(item)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectInput;
