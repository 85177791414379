import { PublicRoutes } from "./PublicRoutes";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
